import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    ParaOne:
      'Historically, the more traditional Procurement software companies have provided a series of e-Sourcing tools to help their clients to go market, be it RFx, or Reverse Auctions.  As those companies have grown or been acquired, their clients are left with the need to seek e-Sourcing technology to exceed their expectations and support the Procurement team’s mission to reduce costs and support their companies.  Today’s procurement technologies have evolved from being more than just online purchasing portals.  Automation technology for reporting, artificial intelligence for analysis, and cloud-based record-keeping accessible anywhere are all must-have technologies in the Procurement organizations portfolios, given the reduction in cycle time, better internal and external collaboration, transparency, enhanced supplier relationships, cost savings, etc.',
    ParaTwo:
      'Raindrop’s Enterprise Spend Management platform was designed to streamline and digitize the entire RFx process from drafting the RFP, collaborating with internal teams, inviting vetted suppliers to the RFP, qualifying suppliers for the bid, addressing Q&A through a centralized portal, evaluating supplier bids by providing fingertip-ready analytics to make informative decision, and finally awarding the bid.',
    ParaThree:
      'Raindrop recognizes the critical requirement of e-Sourcing technologies and the gap in the current solution space, especially as it relates to companies who need to take their spend competitively out to market, but may not have the technology solutions available to support their needs.  Therefore, this article covers',
    ParaFive: '',
    ParaFivePointOne: 'Challenges with current RFx process and technologies',
    ParaFivePointTwo: 'Why Raindrop is the best solution to your eRFX needs',
    HeadingOne: 'Challenges with current RFx process and technologies',
    ParaSix:
      'The main challenge with today’s RFx process is they are still being done manually or through complex and non-scalable eRFx solutions.  The lack of right technologies to fully support RFx function is lagging technology adoption and holding the procurement function back.  Organizations who are still running the RFx manually are simply not scalable.  The key aspect of any RFx primarily depends on collecting information from suppliers with respect to their capabilities tied to a particular scope and then compare to what other suppliers are offering. With the manual process it becomes very tedious for internal teams as well as suppliers to co-ordinate and end up spending a lot of time-consuming cycles. This is also one of the reasons why RFx is considered to be a very time consuming exercise for any organization.',
    HeadingTwo: 'Why Raindrop is the best solution to your eRFx needs',
    ParaSeven:
      "RFx sourcing events (RFI, RFQ, RFP) can be performed in a fast, consistent, successful manner by Procurement and functional departments in a fraction of the time.  Raindrop's Sourcing Events module Streamline and standardize going out to market to drive value, reduce cost, increase output.  Raindrop was designed to overcome the current system adoption barriers.  A system or platform that requires significant amount of training or is not intuitive will not be easily adopted or scaled in most of the organization.  Raindrop’s interface is clean and simple, which makes it easy to find and access whatever it is you're looking for.  It allows you to sort and filter according to your preference, quickly focusing on the top priorities with respect to an RFx. The easily accessible dashboard displays your RFx cycle and in what exact stage you are in with actional call to actions which is updated in real time, providing instantaneous views into supplier bids and progress to plan. Moreover, every interaction you have with a supplier or a project can be stored in one easily accessible place in the form of an event and chat channel, so that you can quickly refer back to discussions, notes, and meetings.",
    ParaEight:
      'With Raindrop’s Sourcing module, instantly create RFx (RFI, RFQ, RFP) and Reverse Auctions to take your business opportunities competitively out to market.  Collaboratively define your selection criteria, specify bidder questions, confirm your scope requirements, and have internal stakeholders score and calculate responses automatically.  Send Q&A responses to all, or only select bidders, for answers to their inquiries.  Further, you can streamline and accelerate your RFx today with Raindrop’s top notch and highly visual bid analysis capabilities, which provide a side-by-side comparison of the submitted bids.  External and internal communications are also consolidated within the platform via Raindrop’s inbuilt chat technology, so your whole team can stay immediately informed of discussions with suppliers and intra-team chats.',
    HeadingThree: 'Raindrop Sourcing Events Value Proposition',
    HeadingThreePointOne: 'Save Time',
    ParaNine:
      'Increase efficiency and significantly reduce go to market time.  It is not unusual for numerous Procurement teams to use spreadsheets and word processor software programs to support their “go to market” needs.  Unfortunately, these basic tools are timely, inefficient, and not connected to a central Sourcing solution to automate your RFx processes.  Using Raindrop will help teams to automate RFx processes from drafting, inviting bidders, evaluating bids and final award.',
    HeadingThreePointTwo: 'Drive Savings',
    ParaTen:
      'Avoid out of compliance spending and cost overruns.  Using Machine Learning (ML) and Artificial Intelligence (AI) to gain insights on commodity pricing, resulting in market-correct pricing guidance, and harness spend leakage. ',
    HeadingThreePointThree: 'Manage Risk',
    ParaEleven:
      'With Raindrop’s supplier vetting functionality, get thorough insights on your suppliers, determine if the supplier is right for you, or if alternate actions need to be taken.',
    HeadingThreePointFour: 'Collaborate',
    ParaTwelve:
      'Easily share documents and templates with internal teams and suppliers. Collaborate in real time with internal team and suppliers with option to interact on a public screen or private screen.',
    HeadingThreePointFive: 'Operational Efficiency',
    ParaThirteen:
      'Capture your existing workflows or easily set up new workflows to create a streamlined operational function with your RFx. With a fully integrated process, everyone is on the same page and the project runs smoothly.',
    HeadingThreePointSix: 'Powerful Bid Analysis',
    ParaFourteen:
      ' Raindrop extensive and easy to access bid evaluation feature allows for the inclusion or exclusion of data and different groupings. This allows to score bidders for different weightings to be placed on the received inputs such as price, delivery, service levels and more. Stakeholder input so that you can solicit opinions and grades from different members of your internal team',
    PointPara: '⟶',
    HeadingThreePointSeven: 'Analytics driven dashboard capabilities',
    ParaFour:
      'Raindrop’s sourcing event dashboard allows for easy reporting across the entire organization and to various stakeholders. Customize the dashboard input using filters to only see relevant event data.',
  },
]
