import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import RFPProcessRestData from '../Data/RFPProcessRestData'
// import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'

const AppRFPProcessRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .RFPProcessContentSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .RFPProcessHeadingEmail {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RFPProcessLeadParaStyleOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RFPProcessLeadParaStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .RFPProcessLeadParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .achorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 0px;
    text-decoration: none;
  }
  .achorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .PointColor {
    font-size: 22px;
    font-weight: bold;
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
`

export const AppRFPProcessRest = () => {
  return (
    <AppRFPProcessRestWapper>
      <div>
        {RFPProcessRestData.map((data, index) => {
          return (
            <div className='MainRFPProcessNeedsSection' key={index}>
              <div className='RFPProcessContentSectionEmail'>
                <ReUsePtag para={data.ParaOne} paraStyle='RFPProcessLeadParaStyleOne' />
                <ReUsePtag para={data.ParaTwo} paraStyle='RFPProcessLeadParaStyle' />
                <ReUsePtag para={data.ParaThree} paraStyle='RFPProcessLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.ParaFivePointOne}
                  paraStyle='RFPProcessLeadParaStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.ParaFivePointTwo}
                  paraStyle='RFPProcessLeadParaStyle'
                  IconStyle='iconColor'
                />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle='RFPProcessHeadingEmail' />
                <ReUsePtag para={data.ParaSix} paraStyle='RFPProcessLeadParaStyle' />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle='RFPProcessHeadingEmail' />
                <ReUsePtag para={data.ParaSeven} paraStyle='RFPProcessLeadParaStyle' />
                <ReUsePtag para={data.ParaEight} paraStyle='RFPProcessLeadParaStyle' />
                <ReUseHTwotag Heading={data.HeadingThree} HeadingStyle='RFPProcessHeadingEmail' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingThreePointOne}
                  paraStyle='RFPProcessLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaNine} paraStyle='RFPProcessLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingThreePointTwo}
                  paraStyle='RFPProcessLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaTen} paraStyle='RFPProcessLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingThreePointThree}
                  paraStyle='RFPProcessLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaEleven} paraStyle='RFPProcessLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingThreePointFour}
                  paraStyle='RFPProcessLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaTwelve} paraStyle='RFPProcessLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingThreePointFive}
                  paraStyle='RFPProcessLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaThirteen} paraStyle='RFPProcessLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingThreePointSix}
                  paraStyle='RFPProcessLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaFourteen} paraStyle='RFPProcessLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingThreePointSeven}
                  paraStyle='RFPProcessLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaFour} paraStyle='RFPProcessLeadParaStyle' />
              </div>
            </div>
          )
        })}
      </div>
    </AppRFPProcessRestWapper>
  )
}
